<template>
  <div class="vipShopList_father">
    <div class="headImg">
      <swipe :autoplay="3000" class="height100">
        <swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" />
        </swipe-item>
      </swipe>
    </div>
    <div class="vipShopList">
      <div class="box" v-for="(item, index) in shopList" :key="index" @click="goDetail(item)">
        <div class="box_top">
          <div class="t_left">
            <img :src="item.image" alt />
          </div>
          <div class="t_right">
            <div class="title">
              <div class="title_l">{{item.name}}</div>
            </div>
            <div class="address">
              <div class="b_b_left">
                <img src="@static/vip/myaddress.png" alt style="width: 10px;" />
                {{item.area}} {{item.address}}
              </div>
              <!-- <div class="title_r" v-if="isWeixin_status">
                <span>{{item.dis}}Km</span>
              </div> -->
            </div>
          </div>
        </div>
        <div class="prices">
          <div class="prices_lft">
            <Tag mark plain type="primary" class="width80">今日油价</Tag>
          </div>
          <div class="prices_rgt" >
            <swipe :loop="false" :width="70" :show-indicators="false">
              <template  v-for="(subItem, index) in item.goods">
                <swipe-item  :key="index">
                  <div class="p">
                    <p class="product">{{subItem.name}}</p>
                    <span class="money">¥{{subItem.price}}</span>
                  </div>
                </swipe-item>
              </template>
              
            </swipe>
            
          </div>
        </div>
        <!-- 拨打电话 -->
        <div class="operation">
          <a :href="`tel:${item.mobile}`" class="ringUp">
            <icon size="20" name="phone-o" />
            <span>拨打电话</span>
          </a>
          <div class="navigation" @click.stop="goAddress(item)">
            <icon size="20" name="location-o" />
            <span>立即前往</span>
          </div>
        </div>
      </div>
      <div class="lookOverMore" @click="lookOverMore" v-if="show">查看更多</div>
      <div class="lookOverMore noBack" v-else>没有更多了~</div>
    </div>
  </div>
</template>

<script>
import {
  Tag,
  Swipe,
  SwipeItem,
  Icon,
} from "vant";
import wx from 'weixin-js-sdk';
import { goWxConfigJsApi } from '@/utils/wxpay';
import { getDistance } from '@/utils/tools'; 
import Creditshop from "@/api/creditshop";
export default {
  components: {
    Tag,
    Swipe,
    SwipeItem,
    Icon,
  },
  data() {
    return {
      screenvalue: "",
      page: 0,
      shopList: [],
      show: true,
      images: [
        require('@static/vip/ban_1.png'),
        require('@static/vip/ban_2.png')
      ],
      value1: "价格",
      value2: "a",
      value3: "a",
      latitude: "",
      longitude: "",
    };
  },
  created() {

    if (this.isWeixin_status) {
      // 微信配置
      goWxConfigJsApi('openLocation,getLocation');
    }
  },
  mounted() {
    this.$nextTick(() =>{
      if (this.isWeixin_status) {
        if (JSON.parse(sessionStorage.getItem('store_all'))) {
          this.shopList = JSON.parse(sessionStorage.getItem('store_all'));
        } else {
          this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true, // 禁用背景点击
            message: "加载中...",
            loadingType: 'spinner',
            selector: '#custom-selector',
          });
          this.getShopList();
        }
      } else {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true, // 禁用背景点击
          message: "加载中...",
          loadingType: 'spinner',
          selector: '#custom-selector',
        });
        this.getShopList();
      }
    }, 500)
  },
  methods: {
    // 详情页
    goDetail(item) {
      sessionStorage.setItem('now_store', JSON.stringify(item));
      if (this.isWeixin_status) {
        this.$router.push(`/station_des?id=${item.id}&store_id=${item.store_id}&km=${item.dis}`);
      } else {
        this.$router.push(`/station_des?id=${item.id}&store_id=${item.store_id}`);
      }
    },
    // 加载更多
    lookOverMore() {
      this.getShopList();
    },
    // 计算两地之间距离
    setDistance() {
      var _this = this;
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          let arr = [];
          for (let i = 0; i < _this.shopList.length; i++) {
            // 计算距离
            _this.shopList[i].dis = 
              getDistance(_this.shopList[i].location.lat,_this.shopList[i].location.lng, res.latitude, res.longitude)
            arr.push(_this.shopList[i]);
          }
          _this.shopList = _this.quickSort(arr);
          _this.$toast.clear();
        }
      });
    },
    // 调用微信地址
    goAddress(item) {
      if (this.isWeixin_status) {
        wx.ready(() => {
          wx.openLocation({
            latitude: Number(item.lat), 
            longitude: Number(item.lng),
            name: Number(item.area), 
            scale: 16, 
            fail: (res) => {
              console.log(res);
            }
          })
        })
      } else {
        this.$toast('请在微信环境中打开...');
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async getShopList() {
      this.page++;
      let store_id = "";
      try {
        const res = await Creditshop.shopList(store_id, this.page);
        if (res.code == 200) {
          if (res.data.length > 0) {
            if (this.isWeixin_status) {
              for (let i in res.data) {
                this.shopList.push(res.data[i])
              }
              if (this.shopList.length == res.data.total) {
                this.show = false
              }
              console.log('AaaaaasetDistance')
              this.$toast.clear();
              // this.setDistance();
            } else {
              for (let i in res.data) {
                this.shopList.push(res.data[i])
              }
              if (this.shopList.length == res.data.total) {
                this.show = false
              }
              this.$toast.clear();
            }
          } else {
            this.$toast.clear();
          }
        }
        if (res.code == 400) {
          this.show = false;
          this.page = 1;
          this.$toast.clear();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.headImg {
  height: 150px;
  margin-bottom: 5px;
  position: relative;
  .height100 {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .screen {
    opacity: 0.6;
    position: absolute;
    width: 100%;
    left: 0;
    top: 30px;
  }
}
.small_module {
  // height: 70px;
  display: flex;
  flex-direction: row;
  margin: 5px 10px;
  padding: 10px 5px;
  background: #fff;
  .lft,
  .rgt {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 5px;
    .lft_img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .lft_detail {
      .top {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
      }
      .btm {
        font-size: 12px;
        color: #b9b9b9;
      }
    }
  }
  .lft:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 40px;
    right: 3px;
    top: 50%;
    margin-top: -20px;
    background: rgb(192, 192, 192);
  }
}
.vipShopList {
  background: #f3f3f3;
  padding: 0px 10px 15px;
  padding-bottom: 50px;
  padding-top: 10px;
  .box {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    padding: 0px 6px;
    // padding-bottom: 10px;
    .box_top {
      height: auto;
      padding: 10px 2px;
      display: flex;
      .t_left {
        width: 50px;
        height: 50px;
        margin-right: 14px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .address {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
      }
      .t_right {
        flex: 1;
        .title {
          // height: 16px;
          display: flex;
          justify-content: space-between;
          .title_l {
            font-size: 18px;
            font-weight: bold;
            color: #333;
          }
        }
        .title_r {
          flex: 1;
          margin-left: 10px;
          padding: 3px 5px;
          background: #ccac39;
          color: #fff;
          text-align: center;
          border-radius: 5px;
        }

        .sign {
          width: 110px;
          height: 18px;
          font-size: 11px;
          display: flex;
          border-radius: 3px;
          padding: 0 2px;
          align-items: center;
          justify-content: space-between;
          background: linear-gradient(
            180deg,
            rgba(55, 150, 255, 1) 0%,
            rgba(113, 198, 255, 1) 100%
          );
          div:nth-child(1) {
            color: #fff;
            border-radius: 2px 0px 0px 2px;
          }
          div:nth-child(2) {
            background: #fff;
            padding: 0 4px;
            color: rgba(55, 150, 255, 1);
          }
        }
      }
      .b_b_left {
        // margin-top: 5px;
        width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .prices {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      color: #cc393b;
      // padding-top: 14px;
      .prices_lft {
        mix-width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        .width80 {
          width: auto;
          text-align: center;
          height: 16px;
          line-height: 16px;
          padding: 2px 5px;
          justify-content: center;
        }
      }
      .prices_rgt {
        text-align: center;
        flex: 1;
        display: flex;
        flex-direction: row;
        width: 250px;
      }
      .p {
        flex: 1;
        width: 58px;
        margin: 0 3px;
        background: #cc393b14;
        padding: 3px 2px;
        border-radius: 4px;       
      }
      .money {
        font-size: 14px;
        font-weight: 600px;
      }
    }
    .operation {
      margin-top: 8px;
      border-top: 1px solid #b1b1b145;
      border-bottom: 1px solid #b1b1b145;
      height: 40px;
      display: flex;
      flex-direction: row;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      span {
        margin-left: 10px;
      }
      .ringUp {
        color: #333;
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ringUp:after {
        position: absolute;
        content: "";
        width: 1px;
        height: 34px;
        right: 0;
        top: 50%;
        margin-top: -17px;
        background: #b1b1b145;
      }
      .navigation {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .shopIfor {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 5px 14px 10px;
      .tel {
        height: 18px;
        line-height: 18px;
        // font-size: 14px;
        // margin-top: 15px;
      }
      .businessTime {
        height: 18px;
        line-height: 18px;
        // margin-top: 10px;
      }
    }

    .box_bottom {
      width: 100%;
      border-top: 1px solid #d8d8d8;
      height: auto;
      display: flex;
      padding: 10px 20px;
      align-items: center;
      justify-content: space-between;
      .b_b_right {
        .btn {
          width: 65px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          color: #fff;
          background: linear-gradient(
            180deg,
            rgba(113, 198, 255, 1) 0%,
            rgba(55, 150, 255, 1) 100%
          );
          border-radius: 13px;
        }
      }
    }
  }
  .lookOverMore {
    height: 30px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
  }
  .noBack {
    background: transparent;
  }
}
</style>

<style lang="scss">
.vipShopList_father {
  height: 100vh;
  width: 100%;
  background: #f3f3f3;
  .van-search {
    background: transparent !important;
  }
}
</style>
